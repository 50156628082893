module.exports = {
  siteTitle: "Shivdatt Pandya",
  siteDescription:
    "Shivdatt Pandya works with talented marketing, content and engineering teams to pioneer innovative solutions for seamless digital experience creation, management, and distribution.",
  siteKeywords:
    "Shivdatt Pandya, Shivdatt, Shivdutt, Shiv Pandya, digital producer, product owner, AEM, content producer, customer experience, agile, content strategy, sydney, australia",
  siteUrl: "https://shivo.me",
  siteLanguage: "en_US",
  googleAnalyticsID: "UA-175433305-1",
  googleVerification: "RHi69Fl9lmwJYHlIE7f_TDtojCunsRMKy7SooNbIa_8",
  name: "Shivdatt Pandya",
  location: "Sydney, AU",
  email: "hello@shivo.me",
  socialMedia: [
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/shivdatt-pandya/",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/shivdatt/",
    },
    {
      name: "Polywork",
      url: "https://poly.me/shivdatt",
    },
  ],

  navLinks: [
    {
      name: "About",
      url: "/#about",
    },
    {
      name: "Experience",
      url: "/#jobs",
    },
    {
      name: "Work",
      url: "/#projects",
    },
    {
      name: "Blog",
      url: "/posts",
    },
    {
      name: "Contact",
      url: "/#contact",
    },
  ],

  navHeight: 100,

  colors: {
    green: "#64ffda",
    navy: "#0a192f",
    darkNavy: "#020c1b",
  },

  srConfig: (delay = 200) => ({
    origin: "bottom",
    distance: "20px",
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
    mobile: true,
    reset: false,
    useDelay: "always",
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
