import { hex2rgba } from "@utils";

const ACCENT = "#ec9b23";
const DARK_BG = "#000000";
const BG = "#000000";

const theme = {
  colors: {
    darkNavy: DARK_BG,
    navy: BG,
    lightNavy: "#171717",
    lightestNavy: "#171717",
    slate: "#fff1d5",
    lightSlate: "#fff6e5",
    lightestSlate: "#fff9ef",
    white: "#fffbf4",
    green: ACCENT,
    transGreen: hex2rgba(ACCENT, 0.07),
    shadowNavy: hex2rgba(DARK_BG, 0.5),
  },

  fonts: {
    Calibre:
      "Calibre, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif",
    SFMono:
      "SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, monospace",
  },

  fontSizes: {
    xs: "13px",
    smish: "12px",
    sm: "15px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    xxl: "23px",
    h3: "32px",
  },

  easing: "cubic-bezier(0.645, 0.045, 0.355, 1)",
  transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)",

  borderRadius: "3px",
  navHeight: "100px",
  navScrollHeight: "70px",
  margin: "20px",

  tabHeight: 42,
  tabWidth: 120,
  radius: 3,

  hamburgerWidth: 30,
  hamBefore: `top 0.1s ease-in 0.25s, opacity 0.1s ease-in`,
  hamBeforeActive: `top 0.1s ease-out, opacity 0.1s ease-out 0.12s`,
  hamAfter: `bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  hamAfterActive: `bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s`,

  navDelay: 500,
  loaderDelay: 100,
};

export default theme;
