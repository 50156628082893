import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.5 123">
    <g id="Rounded_Rectangle_1" data-name="Rounded Rectangle 1" isolation="isolate">
      <g id="Rounded_Rectangle_1-2" data-name="Rounded Rectangle 1">
        <path
          d="M88.5,11a25,25,0,0,1,25,25V89a25,25,0,0,1-25,25H36A25,25,0,0,1,11,89V36A25,25,0,0,1,36,11H88.5m0-10H36A35,35,0,0,0,1,36V89a35,35,0,0,0,35,35H88.5a35,35,0,0,0,35-35V36a35,35,0,0,0-35-35Z"
          transform="translate(-1 -1)"
          fill="#e6af2e"
        />
      </g>
    </g>
    <g id="S">
      <path
        d="M41.21,83.46c-5-4.57.06-11.62,5.86-12.47,5.62,2.2,19,16.53,21.67,1.84-7-9.94-30.41-5.25-28.61-24.77-1.94-30.26,61-16.62,32.12.09-4.74-.73-10.1-7.82-15.6-3.39-6.86,7.9,7,9.49,12.59,10.79C107.76,69.32,62.83,112.47,41.21,83.46Z"
        transform="translate(-1 -1)"
        fill="#64ffda"
      />
    </g>
  </svg>
);

export default IconLogo;
